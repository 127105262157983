import React from 'react'

export default function Footer() {
    let date = new Date();
    return (
        <>
            <footer className="footer text-center mt-auto py-4">
                <div className="container">
                    <span className="text-muted">&#169; {date.getFullYear()} by Navi Weather |  Created by <a href="https://www.techspecsmart.com">Naveen Gupta</a> </span>
                </div>
            </footer>
        </>
    )
}
