import React from 'react'

export default function Navbar() {
    return (
        <>
            <div className="container-fluid">
                <div className="row justify-content-center nav-logo">
                    <img src="/images/navi-weather-logo.png" alt="Navi Weather Logo" />
                </div>

            </div>
        </>
    )
}
