import React from 'react'

export default function Info() {
    return (
        <>
            <hr />
            <hr />
            <div className="container my-5 ">
                <h2 className='heading mt-5 mb-3'>How many can possible weather conditions occur?</h2>
                <ul className='my-5'>
                    <li><b>Foggy</b> : This weather condition is characterized by dense clouds of water droplets suspended in the air, which can reduce visibility. The mood associated with foggy weather is often mysterious and eerie.</li>
                    <li><b>Sunny</b> : This weather condition is characterized by bright sunshine and clear blue skies. The mood associated with sunny weather is often cheerful, uplifting, and energizing.</li>
                    <li><b>Cloudy</b> : This weather condition is characterized by an overcast sky with clouds blocking out the sun. The mood associated with cloudy weather is often subdued or contemplative.</li>
                    <li><b>Rainy</b> : This weather condition is characterized by precipitation falling from the sky. The mood associated with rainy weather is often melancholy, pensive, or reflective.</li>
                    <li><b>Snowy</b> : This weather condition is characterized by frozen precipitation falling from the sky. The mood associated with snowy weather is often peaceful, serene, or nostalgic.</li>
                    <li><b>Stormy</b> : This weather condition is characterized by thunder, lightning, and heavy precipitation. The mood associated with stormy weather is often tense, dramatic, or exhilarating.</li>
                    <li><b>Windy</b> : This weather condition is characterized by strong gusts of wind that can make it difficult to move around. The mood associated with windy weather is often restless or invigorating.</li>
                    <li><b>Smoky</b> : This weather condition is characterized by a thick haze of smoke in the air, usually due to wildfires. The mood associated with smoky weather is often eerie or apocalyptic.</li>
                    <li><b>Hazy</b> : This weather condition is characterized by a misty or smoggy appearance in the air, usually due to pollution or humidity. The mood associated with hazy weather is often dreary or suffocating.</li>
                </ul>

                <h2 className='heading mt-5 mb-3'>What is Humidity?</h2>
                <p className='mt-5'>Humidity is a measure of the amount of water vapor in the air. It can affect how comfortable we feel and can also impact the environment around us. Here are some common humidity ranges and their effects:</p>
                <ul className='mb-2'>
                    <li><b>0% - 30% </b> : This humidity range is considered dry. It can cause dry skin, chapped lips, and respiratory irritation. It can also cause static electricity and increase the risk of wildfires.</li>
                    <li><b>30% - 60% </b> : This humidity range is considered comfortable. It can help to keep the skin hydrated and can prevent respiratory problems. It can also prevent static electricity and reduce the risk of wildfires.</li>
                    <li><b>60% - 100% </b> : This humidity range is considered high. It can cause mold and mildew to grow, which can lead to respiratory problems and damage to property. It can also make the air feel heavy and uncomfortable. High humidity can also increase the risk of hurricanes and other weather-related disasters.</li>

                </ul>
                <p>It's important to note that the ideal humidity range can vary depending on personal preference, as well as the climate and environment. However, generally, a humidity range between 30% - 60% is considered the most comfortable and healthy for humans and the environment.</p>
            
                <h2 className='heading mt-5 mb-3'>What is Atmospheric Pressure?</h2>
                <p className='mt-5'>Atmospheric pressure refers to the force exerted by the weight of the Earth's atmosphere on the surface below. It is the result of the gravitational attraction of the Earth on the gas molecules that make up the atmosphere, and is measured in units of pressure such as millibars (mb) or pascals (Pa).</p>
                <p>At sea level, the average atmospheric pressure is approximately 1013 millibars, which is also referred to as one atmosphere (ATM) of pressure. However, atmospheric pressure varies with altitude, as the weight of the atmosphere decreases with increasing elevation. Therefore, the atmospheric pressure is lower at higher altitudes, and this is why climbers on high mountains often experience difficulty breathing due to the thinner air.</p>
                <p>Changes in atmospheric pressure can also affect weather patterns, with high pressure areas typically associated with fair weather and low pressure areas associated with storms and precipitation. Atmospheric pressure is an important factor in many fields of science, including meteorology, geology, and engineering.</p>
            
            </div>
        </>
    )
}
