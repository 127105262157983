import React, { useEffect, useState } from 'react'
// import PropTypes from 'prop-types'
import Info from './Info';
function Weather() {
    let date = new Date();
    const apiKey = 'c3b10145df9a2a0fc0ff7dbf184eb2b3';
    // const apiURL = 'https://api.openweathermap.org/data/2.5/weather?q={city name}&appid={API key}';

    const [searchPlace, setSearchPlace] = useState("Delhi");

    const [tempInfo, setTempInfo] = useState("");

    const getWeatherInfo = async () => {
        try {
            let url = `https://api.openweathermap.org/data/2.5/weather?units=metric&q=${searchPlace}&appid=${apiKey}`;

            const response = await fetch(url);
            const data = await response.json();
            
            // console.log(data);

            const { temp_min, temp_max, temp, pressure, humidity } = data.main;
            const { main: weatherMood, description, id } = data.weather[0];
            const { name: cityName } = data;
            const { country, sunrise, sunset } = data.sys;
            const { speed: windSpeed } = data.wind;
            const { visibility } = data;

            const myNewWeatherData = {
                visibility, temp_min, temp_max, temp, pressure, humidity, weatherMood, description, id, cityName, country, sunrise, sunset, windSpeed
            }

            setTempInfo(myNewWeatherData);
            document.getElementById('error').style.display = 'none';
            // console.log(tempInfo.temp);

        } catch (error) {
            // console.error();
            // console.log('City Not Found');
            document.getElementById('error').style.display = 'block';
            document.getElementById('error').innerHTML = 'City Not Found, Try with other City Name.';
        }


    };
    useEffect(() => {
        getWeatherInfo();
    }, []);

    // ========= time conversion for sunrise ==========
    let sRise = tempInfo.sunrise;
    let dateN = new Date(sRise * 1000);
    let sRtime = dateN.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })

    // let sRtime = `${dateN.getHours()}:${dateN.getMinutes()}`;

    // ===========  time conversion for sunset  ============
    let sSet = tempInfo.sunset;
    let dateNs = new Date(sSet * 1000);
    let sStime = dateNs.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })

    // let sRtime = `${dateNs.getHours()}:${dateNs.getMinutes()}`;

    // ====== wind speed convert to m/sec to km/h ======
    let wSpeed = tempInfo.windSpeed;
    let windSpeed = wSpeed * 3.6;

    // ====== Atmospheric Pressure convert to m/sec to km/h ======
    let aPressure = tempInfo.pressure;
    let nPressure = aPressure * 0.1;

    // ====== Visibility convert to m to km ======
    let aVisi = tempInfo.visibility;
    let nVisibility = aVisi * 0.001;

    // ===========  set state of humidity  ============

    return (
        <>

            <div className='main-container'>
                <div className="row">
                    <div className='col-md-3 left-side'>

                        <div className='container text-center'>

                            <div className="input-group my-4">
                                <input type="text" className="form-control input-box" placeholder="Search for Places..." title="Enter Your Place Name" value={searchPlace} onChange={(e) => setSearchPlace(e.target.value)} />
                                <span className="input-group-text search-btn" onClick={getWeatherInfo} title="Click to Check Weather"><i className="fa-solid fa-search"></i></span><br />
                                <small className='text-danger' id="error"></small>
                            </div>

                            {/* <span className='weather-icon'><i className='wi wi-day-sunny'></i></span> */}
                            <span className='weather-icon'><img src={`/images/${tempInfo.weatherMood}.png`} alt="weather logo" /></span>
                            <span className='card-title-bottom text-uppercase my-2'>{tempInfo.description}</span>
                            <div className="card m-0">
                                <span className='text-big-left'>{Math.round(tempInfo.temp)}<span className='text-sm-up-left'>&deg;C</span></span>
                                <span className='w-state mb-2'>{tempInfo.cityName}, <span className='w-country'> {tempInfo.country}</span></span>
                                <span className='day mb-4'>{date.toLocaleDateString("en-GB")}, <span className='time'>{date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</span></span>

                            </div>


                        </div>
                    </div>

                    <div className='col-md-9 right-side'>
                        <div className=" container mt-4 mb-5">

                            <h3 className='heading mt-2 mb-5'>Today's Highlights</h3>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="card shadow-sm">
                                        <div className="card-body">
                                            <span className="card-title">Temperature Status</span>
                                            <div className="row align-items-center" >
                                                <div className="col-7">

                                                    <div className="row">
                                                        <div className="col-12 py-2 b-bottom"><span className='text-temp'>Max : {Math.round(tempInfo.temp_max)} <span className='text-temp-up'>&deg;C</span></span></div>
                                                        <div className="col-12 my-2"><span className='text-temp'>Min : {Math.round(tempInfo.temp_min)} <span className='text-temp-up'>&deg;C</span></span></div>
                                                    </div>

                                                </div>
                                                <div className="col-5 text-end">
                                                    {/* <span className='text-big pe-3'><i className='wi wi-thermometer'></i></span> */}
                                                    <span className='text-sm-logo'><img className="w-100" src="/images/temperature.png" alt="Temperature" /></span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card shadow-sm">
                                        <div className="card-body">
                                            <span className="card-title">Wind Status</span>
                                            <div className="row align-items-center">
                                                <div className="col-7">
                                                    <span className='text-big'>{Math.round(windSpeed)}</span>
                                                    <span className='text-sm'>km/h</span>
                                                </div>
                                                <div className="col-5 text-end">
                                                    {/* <span className='text-big pe-3'><i className='wi wi-strong-wind'></i></span> */}
                                                    <span className='text-sm-logo'><img className="w-100" src="/images/wind.png" alt="Wind" /></span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card shadow-sm">
                                        <div className="card-body">
                                            <span className="card-title">Sunrise & Sunset</span>
                                            <div className="col-12 my-2">
                                                <div className="row align-items-center b-bottom">
                                                    <div className="col-8">
                                                        <span className='text-sm-text'>{sRtime}</span>

                                                    </div>
                                                    <div className="col-4">
                                                        {/* <span className='text-sm-logo'><i className='wi wi-sunrise'></i></span> */}
                                                        <span className='text-sm-logo'><img width={49} src="/images/sunrise.png" alt="Sunrise" /></span>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="row align-items-center">
                                                    <div className="col-8">
                                                        <span className='text-sm-text'>{sStime}</span>

                                                    </div>
                                                    <div className="col-4">
                                                        {/* <span className='text-sm-logo'><i className='wi wi-sunset'></i></span> */}
                                                        <span className='text-sm-logo'><img width={49} src="/images/sunset.png" alt="Sunset" /></span>
                                                    </div>

                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card shadow-sm">
                                        <div className="card-body">
                                            <span className="card-title">Humidity</span>
                                            <div className="row align-items-center">
                                                <div className="col-7">
                                                    <span className='text-big'>{tempInfo.humidity}<span className='text-sm-up'>%</span> </span>
                                                </div>
                                                <div className="col-5 text-end">
                                                    {/* <span className='text-big pe-3'><i className='wi wi-humidity'></i></span> */}
                                                    <span className='text-sm-logo'><img className="w-100" src="/images/humidity.png" alt="Humidity" /></span>
                                                </div>
                                            </div>
                                            {/* <span className="card-title-bottom">{(tempInfo.humidity < 60) ? 'Normal' : 'High'}</span> */}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="card shadow-sm">
                                        <div className="card-body">
                                            <span className="card-title">Visibility</span>
                                            <div className="row align-items-center">
                                                <div className="col-7">
                                                    <span className='text-big'>{Math.round(nVisibility)}</span>
                                                    <span className='text-sm'>km</span>
                                                </div>
                                                <div className="col-5 text-end">
                                                    {/* <span className='text-big pe-3'><i className="fa-solid fa-head-side-mask"></i></span> */}
                                                    <span className='text-sm-logo'><img className="w-100" src="/images/visibility.png" alt="Visibility" /></span>
                                                </div>
                                            </div>
                                            {/* <span className="card-title-bottom">Unhealthy</span> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card shadow-sm">
                                        <div className="card-body">
                                            <span className="card-title">Atmospheric Pressure </span>
                                            <div className="row align-items-center">
                                                <div className="col-7">
                                                    <span className='text-big'>{nPressure.toFixed(0)}</span>
                                                    <span className='text-sm'>kPa</span>
                                                </div>
                                                <div className="col-5 text-end">
                                                    {/* <span className='text-big pe-3'><i className="fa-solid fa-eye-low-vision"></i></span> */}
                                                    <span className='text-sm-logo'><img className="w-100" src="/images/atmospheric.png" alt="Atmospheric" /></span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Info />
                    </div>
                </div>

            </div>

        </>

    )
}


// Weather.propTypes = {

// }

export default Weather

